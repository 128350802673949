/* eslint-disable @typescript-eslint/no-explicit-any */
import { rewindGeometry } from '@placemarkio/geojson-rewind'; // TODO: consider implementing these functions in this module instead of using dependencies
// Assert
export function isPoint(input) {
    return (Array.isArray(input) &&
        input.length === 2 &&
        typeof input[0] === 'number' &&
        typeof input[1] === 'number');
}
export function isLineString(input) {
    return Array.isArray(input) && input.every(isPoint);
    // && !isClosed(input) // Possible addition if we want to check for closedness
}
// TODO: check if we keep Ring as unclosed.
// This function is not exported because Ring should not be used externally, since it can not be distingised from LineSting
function isRing(input) {
    return (Array.isArray(input) && input.every(isPoint)
    // && isClosed(input) === closed // Possible addition if we want to check for closedness, with closed an input parameter with default false
    );
}
export function isPolygon(input) {
    return Array.isArray(input) && input.every(isRing);
}
export function isMultiPoint(input) {
    return Array.isArray(input) && input.every(isPoint);
}
export function isMultiLineString(input) {
    return Array.isArray(input) && input.every(isLineString);
}
export function isMultiPolygon(input) {
    return Array.isArray(input) && input.every(isPolygon);
}
export function isGeometry(input) {
    return (isPoint(input) ||
        isLineString(input) ||
        isPolygon(input) ||
        isMultiPoint(input) ||
        isMultiLineString(input) ||
        isMultiPolygon(input));
}
// Conform
export function conformLineString(lineString) {
    // Filter out repeated points
    lineString = lineString.filter(function (point, i, originalLineString) {
        return i === 0 || !isEqualPoint(point, originalLineString[i - 1]);
    });
    if (lineString.length < 2) {
        throw new Error('LineString should contain at least 2 points');
    }
    return lineString;
}
export function conformRing(ring) {
    // Filter out repeated points
    ring = ring.filter(function (point, i, originalRing) {
        return i === 0 || !isEqualPoint(point, originalRing[i - 1]);
    });
    // Remove last point if input is closed ring
    if (isClosed(ring)) {
        ring.splice(-1);
    }
    if (ring.length < 3) {
        throw new Error('Ring should contain at least 3 points');
    }
    return ring;
}
export function conformPolygon(polygon) {
    return polygon.map((ring) => {
        return conformRing(ring);
    });
}
export function conformMultiLineString(multiLineString) {
    return multiLineString.map((lineString) => conformLineString(lineString));
}
export function conformMultiPolygon(multiPolygon) {
    return multiPolygon.map((polygon) => conformPolygon(polygon));
}
// Convert to GeoJSON
export function convertPointToGeojsonPoint(point) {
    return {
        type: 'Point',
        coordinates: point
    };
}
export function convertLineStringToGeojsonLineString(lineString) {
    return {
        type: 'LineString',
        coordinates: lineString
    };
}
export function convertRingToGeojsonPolygon(ring, close = true) {
    const geometry = {
        type: 'Polygon',
        coordinates: close ? [[...ring, ring[0]]] : [ring]
    };
    return rewindGeometry(geometry);
}
export function convertPolygonToGeojsonPolygon(polygon, close = true) {
    const geometry = {
        type: 'Polygon',
        coordinates: close
            ? polygon.map((ring) => {
                return [...ring, ring[0]];
            })
            : polygon
    };
    return rewindGeometry(geometry);
}
export function convertMultiPointToGeojsonMultiPoint(multiPoint) {
    return {
        type: 'MultiPoint',
        coordinates: multiPoint
    };
}
export function convertMultiLineStringToGeojsonMultiLineString(multiLineString) {
    return {
        type: 'MultiLineString',
        coordinates: multiLineString
    };
}
export function convertMultiPolygonToGeojsonMultiPolygon(multiPolygon, close = true) {
    const geometry = {
        type: 'MultiPolygon',
        coordinates: close
            ? multiPolygon.map((polygon) => polygon.map((ring) => {
                return [...ring, ring[0]];
            }))
            : multiPolygon
    };
    return rewindGeometry(geometry);
}
export function convertGeometryToGeojsonGeometry(geometry) {
    if (isPoint(geometry)) {
        return convertPointToGeojsonPoint(geometry);
    }
    else if (isLineString(geometry)) {
        return convertLineStringToGeojsonLineString(geometry);
    }
    else if (isPolygon(geometry)) {
        return convertPolygonToGeojsonPolygon(geometry);
    }
    else if (isMultiPoint(geometry)) {
        return convertMultiPointToGeojsonMultiPoint(geometry);
    }
    else if (isMultiLineString(geometry)) {
        return convertMultiLineStringToGeojsonMultiLineString(geometry);
    }
    else if (isMultiPolygon(geometry)) {
        return convertMultiPolygonToGeojsonMultiPolygon(geometry);
    }
    else {
        throw new Error('Geometry type not supported');
    }
}
// Check
export function isClosed(input) {
    return (Array.isArray(input) &&
        input.length >= 2 &&
        isEqualPoint(input[0], input[input.length - 1]));
}
export function isEqualPoint(point0, point1) {
    if (point0 === point1)
        return true;
    if (point0 === null || point1 === null)
        return false;
    return point0[0] === point1[0] && point0[1] === point1[1];
}
export function isEqualPointArray(pointArray0, pointArray1) {
    if (pointArray0 === pointArray1)
        return true;
    if (!pointArray0 || !pointArray1)
        return false;
    if (pointArray0.length !== pointArray1.length)
        return false;
    for (let i = 0; i < pointArray0.length; ++i) {
        if (isEqualPoint(pointArray0[i], pointArray1[i]))
            return false;
    }
    return true;
}
export function isEqualPointArrayArray(pointArrayArray0, pointArrayArray1) {
    if (pointArrayArray0 === pointArrayArray1)
        return true;
    if (!pointArrayArray0 || !pointArrayArray1)
        return false;
    if (pointArrayArray0.length !== pointArrayArray1.length)
        return false;
    for (let i = 0; i < pointArrayArray0.length; ++i) {
        if (isEqualPointArray(pointArrayArray0[i], pointArrayArray1[i]))
            return false;
    }
    return true;
}
// Compute
export function pointToPixel(point, translate = [0, 0], size) {
    return point.map((coordinate, index) => {
        let result = Math.floor(coordinate + translate[index]);
        if (size) {
            result = Math.max(result, 0);
            result = Math.min(result, size[index] - 1);
        }
        return result;
    });
}
export function pixelToIntArrayIndex(pixel, size, channels, flipY = false) {
    const column = pixel[0];
    const row = flipY ? size[1] - 1 - pixel[1] : pixel[1];
    return (row * size[0] + column) * channels;
}
export function flipX(point) {
    return [-point[0], point[1]];
}
export function flipY(point) {
    return [point[0], -point[1]];
}
export function midPoint(point0, point1) {
    return [
        (point1[0] - point0[0]) / 2 + point0[0],
        (point1[1] - point0[1]) / 2 + point0[1]
    ];
}
export function mixNumbers(number0, number1, t) {
    return number0 * t + number1 * (1 - t);
}
export function mixPoints(point0, point1, t) {
    return [
        point0[0] * t + point1[0] * (1 - t),
        point0[1] * t + point1[1] * (1 - t)
    ];
}
export function distance(from, to) {
    if (isLineString(from) && from.length === 2) {
        return distance(from[0], from[1]);
    }
    else if (isPoint(from) && isPoint(to)) {
        return Math.sqrt((to[0] - from[0]) ** 2 + (to[1] - from[1]) ** 2);
    }
    else {
        throw new Error('Input type not supported');
    }
}
export function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
}
